import "@fontsource/barlow"
import "@fontsource/barlow/300.css"
import "@fontsource/barlow/400.css"
import "@fontsource/barlow/500.css"
import "@fontsource/barlow/600.css"
import "@fontsource/barlow/700.css"
import {AnimatePresence} from "framer-motion";
import React from "react";

export const wrapPageElement = ({element, props}) => (
    <AnimatePresence mode="wait">{element}</AnimatePresence>
)

export const shouldUpdateScroll = ({
                                       routerProps: {location},
                                       getSavedScrollPosition
                                   }) => {
    const TRANSITION_DELAY = 0.4 * 1000

    if(location.hash !== "") {
        return true;
    }

    if (location.action === "PUSH") {
        window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY)
    } else {
        const savedPosition = getSavedScrollPosition(location) || [0, 0]
        window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
    }
    return false
}
